import { EUNOIA_API_CONNECTOR } from "./apiConnector";
import { EUNOIA_CONFIG } from "./apiConfig";
import store from "../store/index";

export const getLayer = async () => {
	const path = `/layer/${EUNOIA_CONFIG.brandCode}/type`;
	const { get } = EUNOIA_API_CONNECTOR({});
	try {
		const data = await get(path);
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getStores = async (payload) => {
	const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
	let path = `/stores/${code}?`;
	if(isHQ) path = `/outlets/${code}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	const params = {
		...{ app: EUNOIA_CONFIG.app },
		...payload,
	};
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getPromotions = async (outletCode) => {
  const isHQ = store.getters.isHQ;
  let code = EUNOIA_CONFIG.brandCode;
  if (isHQ) code = "hq$" + code;
  var path = `/promotions/${code}`;
  if (outletCode) path = path + "/" + outletCode;
  var params = { app: EUNOIA_CONFIG.app };
  try {
    const { get } = EUNOIA_API_CONNECTOR(params);
    const data = await get(path);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrders = async () => {
	let code = "hq$" + EUNOIA_CONFIG.brandCode;
	const authToken = store.getters.getEunoiaToken;
	const path = `/orders/${code}`;
	const params = {
		extraPayload: {
			pageSize: 100,
			sortOrder: "DESC",
		},
		authToken: authToken,
	};
	const { get } = EUNOIA_API_CONNECTOR(params);
	try {
		const data = await get(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getBookings = async () => {
	let code = "hq$" + EUNOIA_CONFIG.brandCode;
	const authToken = store.getters.getEunoiaToken;
	const path = `/orders/${code}/ticket`;
	const params = {
		extraPayload: {
			pageSize: 100,
			sortOrder: "DESC",
		},
		authToken: authToken,
	}
	const { get } = EUNOIA_API_CONNECTOR(params);
	try {
		const data = await get(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};
